import React from 'react'

export default function ChevronDown({ styles }) {
  return (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...styles }}
    >
      <path
        d="M-0.000194611 1.4L1.39981 -4.10887e-07L5.3998 4L9.3998 -6.11959e-08L10.7998 1.4L5.3998 6.8L-0.000194611 1.4Z"
        fill="#E56846"
      />
    </svg>
  )
}
