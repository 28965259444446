/** @jsx jsx */
import React, { useState, useEffect, useRef } from 'react'
import { jsx, Button } from 'theme-ui'
import ChevronDown from '../icons/chevron-down'

const CollapsibleContent = ({
  content,
  linesToShow,
  markdown = false,
  containerStyles,
  textStyles,
  buttonStyles,
  handleScroll,
  expandText,
  collapseText,
  height,
}) => {
  const [isActive, setIsActive] = useState(false)
  const contentRef = useRef(null)
  const [displayButton, setDisplayButton] = useState(false)
  const [contentMinimumHeight, setContentMinimumHeight] = useState(
    height || linesToShow * 16 || 120
  )

  const handleToggleIsActive = () => {
    if (isActive && handleScroll) {
      handleScroll()
    }
    setIsActive(!isActive)
  }

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight
      let linesToShowHeight = null
      if (!height && linesToShow) {
        const contentLineHeight = parseInt(
          window
            .getComputedStyle(contentRef.current, null)
            .getPropertyValue('line-height')
        )

        // Adding 5 here to offset rounding that seems to occur in actual pixel values of rendered output, value of 5 is arbitrary.
        linesToShowHeight = contentLineHeight * linesToShow + 5
      } else if (height) {
        linesToShowHeight = height * linesToShow
      }
      setContentMinimumHeight(linesToShowHeight)

      if (contentHeight > linesToShowHeight) {
        setDisplayButton(true)
      }

      if (contentHeight <= linesToShowHeight) {
        setDisplayButton(false)
      }
    }
  }, [contentRef.current, content])
  return (
    <div sx={{ overflow: 'hidden', ...containerStyles }}>
      <div
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxHeight: isActive
            ? 'none'
            : contentMinimumHeight
            ? `${contentMinimumHeight}px`
            : 'auto',
          height: 'auto',
          '&:first-child': {
            marginTop: '0px',
          },
        }}
      >
        {markdown ? (
          <div
            ref={contentRef}
            sx={{
              ...textStyles,
              '& *:first-child': {
                marginTop: '0px',
              },
            }}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        ) : (
          <div
            ref={contentRef}
            sx={{
              ...textStyles,
            }}
          >
            {content}
          </div>
        )}
      </div>
      {displayButton && (
        <Button
          variant="viewMore"
          sx={{ marginTop: '1rem', ...buttonStyles }}
          onClick={handleToggleIsActive}
        >
          {!isActive ? expandText || 'Show more' : collapseText || 'Show Less'}
          <ChevronDown
            styles={{
              transform: !isActive ? 'none' : 'rotate(180deg)',
              marginLeft: 4,
            }}
          />
        </Button>
      )}
    </div>
  )
}

export default CollapsibleContent
