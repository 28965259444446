/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Header from '../components/Header'
import theme from '../gatsby-plugin-theme-ui'
import { graphql, Link } from 'gatsby'
import SEO from '../components/SEO'
import { useEffect, useState } from 'react'
import Card from '../components/Card'
import CardList from '../components/CardList'
import CollapsibleContent from '../components/CollapsibleContent'
import PartnerBanner from '../components/PartnerBanner'
import useScrollTo from '../hooks/useScrollTo'

const State = ({ data }) => {
  const [counties, setCounties] = useState([])
  const [stateData, setStateData] = useState({ name: '', slug: '' })
  const [scrollToRef, setShouldScrollTo] = useScrollTo()
  const stateDescriptionMarkdown =
    data.allContentfulState?.nodes[0]?.descriptionText?.childMarkdownRemark
      ?.html
  const stateBannerData = data?.allContentfulState?.nodes[0]?.stateLevelBanner
  const banner = data.allContentfulState.nodes?.[0].banner

  useEffect(() => {
    if (data.allContentfulState.nodes?.[0].counties) {
      setCounties(data.allContentfulState.nodes[0].counties)
      setStateData({
        name: data.allContentfulState.nodes[0].name,
        slug: data.allContentfulState.nodes[0].slug,
      })
    }
  }, [data.allContentfulState.nodes])

  return (
    <Layout>
      <SEO
        title={stateData.name}
        description={`Weather, ocean conditions and safety information for ${stateData.name}.  Information provided directly by the lifeguard and fire departments.`}
        image={data.contentfulAsset && data.contentfulAsset.file.url}
      />
      <Container>
        <Flex
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Header
            height={'30vh'}
            headerText={data.allContentfulState.nodes[0].name}
            image={banner?.gatsbyImageData}
            imageAltText={banner?.description || banner?.title}
            imageCred={banner?.description}
          />
          <Container mw padded>
            <Flex
              sx={{
                justifyContent: 'space-between',
                marginBottom: '27px',
              }}
            >
              <Box sx={{ mt: 45 }}>
                <Link
                  sx={{ color: theme.colors.navLink }}
                  to={`/state/${stateData.slug}`}
                >
                  <span>{stateData.name}</span>
                </Link>
              </Box>
            </Flex>

            {stateDescriptionMarkdown && (
              <CollapsibleContent
                content={stateDescriptionMarkdown}
                markdown={true}
                linesToShow={5}
                handleScroll={() => setShouldScrollTo(true)}
                containerStyles={{ marginBottom: 5, paddingTop: '20px' }}
                textStyles={{ fontSize: [3, 4] }}
                buttonStyles={{ fontSize: [3, 4] }}
              />
            )}
            <h1 ref={scrollToRef}>
              Choose {stateData.name === 'Hawaii' ? 'an Island' : 'a Region'} in{' '}
              {stateData.name}
            </h1>
            <CardList>
              {counties.map((county) => {
                return (
                  <Card
                    key={county.name}
                    imageHeight={'280px'}
                    title={county.name}
                    heroImage={county.image}
                    basePath="county"
                    slug={`${county.slug}`}
                  />
                )
              })}
            </CardList>
          </Container>
          {stateBannerData && <PartnerBanner bannerData={stateBannerData} />}
        </Flex>
      </Container>
    </Layout>
  )
}

export default State

export const query = graphql`
  query StatePage($slug: String!) {
    allContentfulState(filter: { slug: { eq: $slug } }, limit: 1) {
      nodes {
        name
        slug
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 800)
        }
        banner {
          gatsbyImageData(layout: FULL_WIDTH, width: 2400)
          description
          title
        }
        counties {
          id
          slug
          name
          image {
            gatsbyImageData
          }
        }
        descriptionText {
          childMarkdownRemark {
            html
          }
        }
        stateLevelBanner {
          id
          bannerName
          partners {
            id
            partnerName
            partnerUrl
            partnerDescription {
              partnerDescription
            }
            partnerLogo {
              gatsbyImageData(height: 800)
            }
          }
        }
      }
    }
  }
`
